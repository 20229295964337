import { FC, ReactNode, memo } from "react";

import { Theme } from "apps/website/maps/Theme.map";

import {
  paddingClassMap,
  Padding,
  Size,
  sizeClassMap,
} from "./Container.map";

export interface IContainer {
  padding?: Padding;
  className?: string;
  theme?: Theme;
  component?: string;
  size?: Size;
  children: ReactNode;
}

const Container: FC<IContainer> = ({ padding = "default", className, theme, component = "Container", size = "default", children }) => (
  <div
    data-component={component}
    className={`mx-auto ${sizeClassMap[size]} ${paddingClassMap[padding]} ${className}`}
    data-theme={theme}
  >
    { children }
  </div>
);

export default memo(Container);
